import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {combineLatest} from 'rxjs';
import {ApiOwnConsignmentService, OwnOfficeDeskConsignmentPostDto} from '|api/sad';
import {
  CheckUnsavedFormDialogService,
  CodebookService,
  ConsignmentUtilsService,
  IFormGroupCheckable,
  ManualPostDialogResult,
  ManualPostDialogResultType,
  namedDtoToOption,
} from '|shared';
import {
  ManualPostDialogData
} from '|modules/office-desk/modules/office-desk-dialogs/components/manual-post-dialog/manual-post-dialog.service';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {
  formatAsLocalIsoDate,
  getTodayMidnight,
  IczDateValidators,
  IczFormControl,
  IczFormGroup,
  IczOption,
  IczValidators
} from '@icz/angular-form-elements';
import {injectModalData, injectModalRef} from '@icz/angular-modal';


@Component({
  selector: 'icz-manual-post-dialog',
  templateUrl: './manual-post-dialog.component.html',
  styleUrls: ['./manual-post-dialog.component.scss'],
  providers: [
    CheckUnsavedFormDialogService
  ]
})
export class ManualPostDialogComponent implements OnInit, IFormGroupCheckable {

  protected loadingIndicatorService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<ManualPostDialogResult>>();
  protected apiOwnConsignmentService = inject(ApiOwnConsignmentService);
  protected codebookService = inject(CodebookService);
  protected checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private consignmentUtilsService = inject(ConsignmentUtilsService);
  private destroyRef = inject(DestroyRef);
  protected modalData = injectModalData<ManualPostDialogData>();

  form = new IczFormGroup({
    name: new IczFormControl<Nullable<string>>(null),
    originatorName: new IczFormControl<Nullable<string>>({value: null, disabled: true}, []),
    originatorAddress: new IczFormControl<Nullable<string>>({value: null, disabled: true}, []),
    toPostFrom: new IczFormControl<Nullable<string>>({value: null, disabled: true}, [IczValidators.required()]),
    postingDuration: new IczFormControl<Nullable<number>>(null, [IczValidators.isInteger()]),
    toPostTo: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    officeDeskRegionId: new IczFormControl<Nullable<number>>(null),
    officeDeskCategoryId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
  });

  officeDeskCategoryOptions: IczOption[] = [];
  officeDeskRegionOptions: IczOption[] = [];

  formGroupsToCheck!: string[];

  presentOrFutureDateValidator = IczDateValidators.presentOrFutureDateValidator;

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);
    combineLatest([
      this.codebookService.officeDeskCategories(),
      this.codebookService.officeDeskRegions(),
    ])
      .subscribe(([categories, regions]) => {
        this.officeDeskCategoryOptions = categories.map(namedDtoToOption());
        this.officeDeskRegionOptions = regions.map(namedDtoToOption());
      });
    this.form.patchValue(this.modalData.consignment);
    this.form.get('toPostFrom')!.setValue(formatAsLocalIsoDate(getTodayMidnight())); // Manual post action must be today, overriding original toPostFrom

    this.consignmentUtilsService.registerCalcPostingDays(this.form, this.destroyRef);
  }

  manualPost() {
    const formValue = this.form.getRawValue();

    const body: OwnOfficeDeskConsignmentPostDto = {
      name: formValue.name!,
      toPostFrom: formValue.toPostFrom!,
      postingDuration: formValue.postingDuration!,
      toPostTo: formValue.toPostTo!,
      officeDeskRegionId: formValue.officeDeskRegionId!,
      officeDeskCategoryId: formValue.officeDeskCategoryId!,
    };
    const reqData = {id: this.modalData.consignment.id, body};
    const req$ = this.apiOwnConsignmentService.ownConsignmentManualPostOfficeDesk(reqData);

    this.loadingIndicatorService.doLoading(
      req$,
      this,
    ).subscribe(ownConsignment => {
      this.modalRef.close({
        resultType: ManualPostDialogResultType.POSTED,
        consignment: ownConsignment,
      } as ManualPostDialogResult);
    });
  }

  close() {
    this.modalRef.close(null);
  }

}
